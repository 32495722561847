import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getEnvironmentConfig } from '../environment/getEnvironmentConfig';

// Sentry.close() does not work when unmounting, so we have to use a global
let sentryInit = false;

export async function setupSentry() {
  if (sentryInit) {
    return;
  }

  // TODO: should be part of base config
  const { tunnel, environment } = getEnvironmentConfig();
  if (process.env.NODE_ENV === 'development') {
    return 'dev';
  }
  const release = '320c3426bdb588fdc12f9e5d02a157ab50d61438';

  Sentry.init({
    dsn: 'https://5841dbed41214655b42bf9f0396cd212@sentry.monitoring.tredition.com/2',
    integrations: [new BrowserTracing()],
    tunnel,
    environment,
    // 320c3426bdb588fdc12f9e5d02a157ab50d61438 placeholder will be replaced by ci with current commit hash
    release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });

  sentryInit = true;

  return release;
}
