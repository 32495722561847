import styles from './InfoButton.module.css';
import VisuallyHidden from '../../shared/visuallyHidden/VisuallyHidden';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useState } from 'react';
import { useClickOutside } from '../../lib/useClickOutside';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

interface IMaybeHtmlContent {
  isShown: boolean;
  dangerouslySetInnerHTML: boolean;
  infoText: string;
}

const MaybeHtmlContent: FC<IMaybeHtmlContent> = ({
  isShown,
  dangerouslySetInnerHTML,
  infoText,
}) => {
  if (dangerouslySetInnerHTML) {
    return (
      <div
        className={`${styles.infoBox} ${isShown && styles.visible}`}
        dangerouslySetInnerHTML={{ __html: infoText }}
      />
    );
  }
  return <div className={`${styles.infoBox} ${isShown && styles.visible}`}>{infoText}</div>;
};

interface IShowInfo {
  hover: boolean;
  click: boolean;
}

export interface IInfoButton {
  infoText: string;
  className?: string;
  /**
   * If true, the infoText will be rendered as HTML.
   */
  dangerouslySetInnerHTML?: boolean;
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
}

export const InfoButton = ({
  infoText,
  className,
  dangerouslySetInnerHTML = false,
  placement = 'bottom-start',
  ...rest
}: IInfoButton) => {
  const [showInfo, setShowInfo] = useState<IShowInfo>({ hover: false, click: false });
  const { t } = useTranslation();

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const onClickOutside = useCallback(() => {
    setShowInfo({ hover: false, click: false });
  }, [setShowInfo]);

  useClickOutside(referenceElement, onClickOutside, showInfo.click || showInfo.hover);

  const isShown = showInfo.hover || showInfo.click;

  const toggleInfoClick = () => setShowInfo({ ...showInfo, click: !showInfo.click });
  const setInfoHover = (isVisible: boolean) => setShowInfo({ ...showInfo, hover: isVisible });

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <div className={classNames(styles.wrapper, className)}>
      <button
        {...rest}
        type={'button'}
        ref={setReferenceElement}
        onClick={toggleInfoClick}
        onMouseEnter={() => setInfoHover(true)}
        onMouseLeave={() => setInfoHover(false)}
        className={styles.button}>
        <span className={styles.i}>i</span>
        <VisuallyHidden>{t('Helps')}</VisuallyHidden>
        {isShown && (
          <div
            ref={setPopperElement}
            style={{ ...popperStyles.popper, zIndex: 1, textAlign: 'left' }}
            {...attributes.popper}>
            <MaybeHtmlContent
              isShown={isShown}
              infoText={infoText}
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
          </div>
        )}
      </button>
    </div>
  );
};
